import { RedDotListData, TmapApp } from '@tmap-web-lib/tmap-app-interface'
import dayjs from 'dayjs'
import { useAtom, useAtomValue } from 'jotai/index'
import { debounce } from 'lodash-es'
import Image from 'next/image'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { insuranceBannerAtom, storageInsuranceExpiredDate } from '@/atoms'
import { sendEventLog } from '@/features/log/log.fn'
import MyLogDefinition from '@/features/log/log.my'
import { useOpenService } from '@/hooks'
import { useRegisterRedDot } from '@/react-query'
import { InsuranceBannerType } from '@/utils'

import * as style from './vehicleInfo.css'

export interface VehicleInfoProps {
  insuranceRegDate: string
  carNumber: string | null
  vehicleType: string
  fuelType: string
  isParking: boolean | null
  insuranceReddot: RedDotListData[]
}

function VehicleInfo(props: VehicleInfoProps) {
  const { carNumber, vehicleType, fuelType, isParking, insuranceRegDate, insuranceReddot } = props
  const openService = useOpenService()
  const remoteInsuranceBanner = useAtomValue(insuranceBannerAtom)
  const { action_id } = MyLogDefinition.my
  const { mutateAsync: registerRedDot } = useRegisterRedDot({ useErrorBoundary: false })
  const [insuranceBanner, setInsuranceBanner] = useState<InsuranceBannerType | null>(null)
  const [isInsuranceTarget, setIsInsuranceTarget] = useState(false)
  const [insuranceExpiredDateStorage, setInsuranceExpiredDateStorage] = useAtom(
    storageInsuranceExpiredDate
  )

  const callClickDebouncing = useMemo(
    () =>
      debounce(
        () => {
          const custom = { car_number: carNumber ? 'Y' : 'N' }
          sendEventLog(action_id?.tapCarprofile, custom)
          openService('tmap://carprofile')
        },
        1000,
        { leading: true, trailing: false }
      ),
    [openService, action_id?.tapCarprofile, carNumber]
  )

  const handleClick = useCallback(() => {
    console.log('!!!!!!vehicle_info button ')
    callClickDebouncing()
  }, [callClickDebouncing])

  const callInsuranceBtnDebouncing = useMemo(
    () =>
      debounce(
        () => {
          const expiredDate = dayjs(insuranceRegDate, 'YYYYMMDD').add(1, 'year').format('YYYYMMDD')
          setIsInsuranceTarget(false)
          setInsuranceExpiredDateStorage(expiredDate)

          TmapApp.updateRedDotList({
            updateData: [
              {
                itemGroup: 'SERVICE_INDEX',
                item: 'MY_INSURANCE',
                displayType: 'REDDOT',
                readDateTime: dayjs().format('YYYYMMDDHHmmss'),
              },
            ],
          })
          TmapApp.updateAllRedDots()

          sendEventLog(action_id?.tapUbiBanner, {
            body_text: insuranceBanner?.bodyText,
            btn_text: insuranceBanner?.btnText,
          })

          if (insuranceBanner?.url) {
            openService(insuranceBanner.url)
          }
        },
        1000,
        { leading: true, trailing: false }
      ),
    [
      action_id?.tapUbiBanner,
      openService,
      insuranceBanner,
      setInsuranceExpiredDateStorage,
      insuranceRegDate,
    ]
  )

  const handleInsuranceButtonClick = useCallback(() => {
    callInsuranceBtnDebouncing()
  }, [callInsuranceBtnDebouncing])

  const checkInsuranceTarget = useCallback(
    (insuranceRegDate: string) => {
      console.log('insuranceRegDate', insuranceRegDate)
      const insRegMonth = dayjs(insuranceRegDate, 'YYYYMMDD').month() + 1
      const currentMonth = dayjs().month() + 1
      const expired = dayjs(insuranceRegDate, 'YYYYMMDD').add(1, 'year').format('YYYYMMDD')
      console.log('expired', expired)

      if (currentMonth + 1 === insRegMonth || currentMonth === insRegMonth) {
        setIsInsuranceTarget(true)
        registerRedDot([
          {
            itemGroup: 'SERVICE_INDEX',
            item: 'MY_INSURANCE',
            startDateTime: `${insuranceRegDate}000000`,
            expiredDateTime: `${expired}235959`,
            displayType: 'REDDOT',
            minAppVersion: '00.00.00',
            maxAppVersion: '99.99.99',
          },
        ]).then(() => {
          TmapApp.updateAllRedDots()
        })
      } else {
        setIsInsuranceTarget(false)
      }
    },
    [registerRedDot]
  )

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onResume', async () => {
      console.log('vehicle info onResume')
    })
  }, [])

  useEffect(() => {
    const expired = dayjs(insuranceRegDate, 'YYYYMMDD').add(1, 'year').format('YYYYMMDD')
    console.log('insuranceExpiredDateStorage', insuranceExpiredDateStorage)
    console.log('expired', expired)

    if (insuranceReddot.length && insuranceRegDate) {
      if (insuranceReddot[0].startDateTime === `${insuranceRegDate}000000`) {
        setIsInsuranceTarget(true)
      } else {
        // TmapApp.updateRedDotList({
        //   updateData: [
        //     {
        //       itemGroup: 'SERVICE_INDEX',
        //       item: 'MY_INSURANCE',
        //       displayType: 'REDDOT',
        //       readDateTime: dayjs().format('YYYYMMDDHHmmss'),
        //     },
        //   ],
        // })
        // TmapApp.updateAllRedDots()
        checkInsuranceTarget(insuranceRegDate)
      }
    } else {
      if (insuranceExpiredDateStorage.length) {
        if (insuranceExpiredDateStorage === expired) {
          setIsInsuranceTarget(false)
        } else {
          setInsuranceExpiredDateStorage('')
          // TmapApp.updateRedDotList({
          //   updateData: [
          //     {
          //       itemGroup: 'SERVICE_INDEX',
          //       item: 'MY_INSURANCE',
          //       displayType: 'REDDOT',
          //       readDateTime: dayjs().format('YYYYMMDDHHmmss'),
          //     },
          //   ],
          // })
          // TmapApp.updateAllRedDots()
          checkInsuranceTarget(insuranceRegDate)
        }
      } else {
        checkInsuranceTarget(insuranceRegDate)
      }
    }
  }, [
    insuranceReddot,
    insuranceRegDate,
    checkInsuranceTarget,
    insuranceExpiredDateStorage,
    setInsuranceExpiredDateStorage,
  ])

  useEffect(() => {
    if (remoteInsuranceBanner.length) {
      setInsuranceBanner(JSON.parse(remoteInsuranceBanner))
    }
  }, [remoteInsuranceBanner])

  useEffect(() => {
    if (isInsuranceTarget) {
      sendEventLog(action_id?.viewUbiBanner, {
        body_text: insuranceBanner?.bodyText,
        btn_text: insuranceBanner?.btnText,
      })
    }
  }, [isInsuranceTarget, action_id?.viewUbiBanner, insuranceBanner])

  return (
    <div
      className={`${style.wrapper}`}
      data-testid="vehicle_info"
      onClick={handleClick}
    >
      <div className={style.carInfoWrapper}>
        <div className={style.carInfo}>
          <p className={`${style.carInfo} ${carNumber ? 'number' : 'text'}`}>
            {carNumber ? carNumber : '내 차 등록하고 차량 관리 받기'}
          </p>
          <span>
            {vehicleType ? vehicleType : null}
            {fuelType ? (
              <>
                &nbsp;&nbsp;
                <span
                  className={style.layer}
                  data-testid="vehicle_fuelType"
                >
                  |
                </span>
                &nbsp;&nbsp;{fuelType}
              </>
            ) : null}
            {isParking ? (
              <>
                &nbsp;&nbsp;
                <span
                  className={style.layer}
                  data-testid="vehicle_parking"
                >
                  |
                </span>
                &nbsp;&nbsp;티맵주차
              </>
            ) : null}
          </span>
        </div>
        <Image
          src={require('../../../../../public/assets/images/ico_arrow_right.svg').default}
          alt=""
        />
      </div>
      {isInsuranceTarget ? (
        <div
          className={style.insuranceModule}
          onClick={(e) => {
            e.stopPropagation()
            handleInsuranceButtonClick()
          }}
        >
          <Image
            className={style.infoImage}
            src={require('../../../../assets/images/ic_my_info.svg').default}
            alt=""
          />
          <div className={style.insuranceModuleContent}>
            <p>
              {insuranceBanner?.bodyText}
              <span className={style.insuranceRedDot} />
            </p>
            <p>{insuranceBanner?.btnText}</p>
            <p>{insuranceBanner?.subText}</p>
          </div>

          <button className={style.closeBtn}>
            <Image
              src={require('../../../../assets/images/ic_my_close.svg').default}
              alt=""
            />
          </button>
        </div>
      ) : null}
    </div>
  )
}

export { VehicleInfo }
