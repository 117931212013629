import { UseMutationResult, useMutation } from '@tanstack/react-query'
import {
  RegisterReddotParam,
  RegisterReddotRes,
  registerReddot,
} from '@tmap-web-lib/remote-api-client/frontman'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { AxiosError } from 'axios'

interface Options {
  useErrorBoundary?: boolean
}

function useRegisterRedDot(
  options: Options
): UseMutationResult<RegisterReddotRes, AxiosError, RegisterReddotParam[]> {
  return useMutation<RegisterReddotRes, AxiosError, RegisterReddotParam[]>(
    async (params) => {
      const { data } = await registerReddot(params)
      return data
    },
    {
      useErrorBoundary: options?.useErrorBoundary ?? true,
    }
  )
}

export { useRegisterRedDot }
