import { UseQueryResult } from '@tanstack/react-query'
import { CarProfileListResData, getCarProfileList } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai/index'

import { mockHost } from '@/apis'
import { storageMockEnabled } from '@/atoms'
import { useQueryWrapper } from '@/hooks/useQueryWrapper'
import { KEYS } from '@/react-query'

interface Props {
  useErrorBoundary?: boolean
}

function useGetCarProfileList(props: Props): UseQueryResult<CarProfileListResData, AxiosError> {
  const storageMock = useAtomValue(storageMockEnabled)

  return useQueryWrapper<CarProfileListResData, AxiosError>(
    [KEYS.CAR_PROFILE_LIST()],
    async () => {
      if (storageMock) {
        const { data } = await mockHost.get('/flerken/api/v3/car-info/$userKey')
        return data
      } else {
        const { data } = await getCarProfileList()
        return data
      }
    },
    {
      useErrorBoundary: props?.useErrorBoundary ?? true,
    }
  )
}

export { useGetCarProfileList }
